import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import NewAgGridTable from './AgGridTable/NewAgGridTable';
import NewFlightTicketModal from 'Views/New/Components/NewFlightTicketModal';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { DS_F2W_Edit } from 'Constants/F2wRolesConstants';

const StatusNew = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const initialModel = {
    firstName: '',
    lastName: '',
    lastName2: '',
    dateOfBirth: '',
    phone: '',
    gender: '',
    staffSourceMarket: '',
    staffSourceMarketBudget: '',
    season: '',
    country: '',
    destination: '',
    jobTitle: '',
    preferredFlightDate: null,
    typeOfFlight: '',
    status: 'PendingBtt',
    isBookReturnFlight: false,
    brfReturnFlightDate: null,
    brfDepartureAirport: '',
    brfArrivalAirport: '',
    isHotelNeeded: false,
    departureAirports: null,
    arrivalAirports: null,
    isRailAndFly: false,
    homeTrainStationName: '',
    noFlightNeeded: false,
  };

  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [newManualFlightTicket, setNewManualFlightTicket] = useState(initialModel);
  const [newManualFlightTicketErrors, setNewManualFlightTicketErrors] = useState({});

  useEffect(() => {
    document.title = 'F2W - New';
  }, []);

  const handleAddManualFlightTicket = (payload) => {
    const errors = {};
    const flightFields = Object.keys(payload);

    // Validate fields
    for (let i = 0; i < flightFields.length; i++) {
      const field = flightFields[i];
      if (
        field === 'lastName2' ||
        field === 'isBookReturnFlight' ||
        field === 'isHotelNeeded' ||
        field === 'isRailAndFly' ||
        field === 'noFlightNeeded' ||
        field === 'flightArrivalTime'
      ) {
        continue;
      }
      if (payload[field] === null || payload[field] === '' || !payload[field]) {
        errors[field] = `Please fill in the ${field}`;
      }
    }

    // Conditional error removal
    if (!payload.isBookReturnFlight) {
      delete errors.brfReturnFlightDate;
      delete errors.brfDepartureAirport;
      delete errors.brfArrivalAirport;
    }
    if (!payload.isRailAndFly) {
      delete errors.homeTrainStationName;
    }
    if (payload.noFlightNeeded) {
      delete errors.departureAirports;
      delete errors.arrivalAirports;
    }

    // Set errors if any
    if (Object.keys(errors).length > 0) {
      setNewManualFlightTicketErrors(errors);
      return;
    }

    // Submit data
    setShowAddCommentModal(false);
    dispatch(setLoader(true));
    RestClient.Upload('flight-ticket/insert-manual', payload.selectedFiles, payload)
      .then((response) => {
        if (response && response?.ok) {
          const LinkComponent = () => (
            <>
              See new ticket: &nbsp;
              <a href={`${document.location.origin}/pendingbtt/${response.id}`}>go to new ticket</a>
            </>
          );
          // create an a component
          toastr.success('Success', {
            component: <LinkComponent />,
          });
        } else {
          toastr.error('Error', response.message);
        }
        dispatch(setLoader(false));
      })
      .finally(() => {
        dispatch(setLoader(false));
        setNewManualFlightTicket(initialModel);
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <h1>New</h1>
          <Button
            variant="primary"
            size="sm"
            style={{ margin: '0 2%' }}
            onClick={() => setShowAddCommentModal(true)}
            hidden={!userRoles.includes(DS_F2W_Edit)}
          >
            Add Manual Request
          </Button>
        </Col>
      </Row>

      <NewAgGridTable />

      <Modal size="lg" show={showAddCommentModal} onHide={() => setShowAddCommentModal(!showAddCommentModal)}>
        <Modal.Header closeButton>
          <Modal.Title>Submit ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewFlightTicketModal
            newManualFlightTicket={newManualFlightTicket}
            setNewManualFlightTicket={setNewManualFlightTicket}
            newManualFlightTicketErrors={newManualFlightTicketErrors}
            setNewManualFlightTicketErrors={setNewManualFlightTicketErrors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" size="sm" onClick={() => handleAddManualFlightTicket(newManualFlightTicket)}>
            Add ticket
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default StatusNew;
