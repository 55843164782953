import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { DarkModeProvider } from 'Configuration/DarkModeContext';

// Import dark mode CSS
import 'Layout/dark-mode.css';

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-datetime/css/react-datetime.css';

// agGrid CSS and License
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('TUI_Nordic__TPP_1Devs6_March_2020__MTU4MzQ1MjgwMDAwMA==76d945771851586658d23f986e58136d');
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-fresh.css';

// Datadog RUM
import { datadogRum } from '@datadog/browser-rum';

// Authentication
import { authContext } from 'Configuration/AdalConfiguration';

// Layout Components
import Layout from 'Layout/Layout';
import TppSwitcher from 'Layout/Components/F2wSwitcher';

// Redux Actions
import { getSeaons, getCountries, getDestinations, getJobTitles } from 'Redux/Actions/MasterData/MasterDataAction';
import { getUser } from 'Redux/Actions/User/UserAction';

// Environment Handler
import { getEnvironmentVariablesHandler } from 'Handlers/EnvHandler';

const APP_CA = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch master data
    dispatch(getSeaons());
    dispatch(getCountries());
    dispatch(getDestinations());
    dispatch(getJobTitles());

    // Fetch user data
    dispatch(getUser());
  }, [dispatch]);

  // console.log('Environment Variables:', getEnvironmentVariablesHandler());

  // Initialize Datadog RUM
  datadogRum.init({
    applicationId: 'a6f03881-8aec-4ac4-bbec-80f6c7771b46',
    clientToken: 'pub509050ee472a98df5ff01e6c71a74789',
    site: 'datadoghq.eu',
    service: 'fly2work-web',
    env: getEnvironmentVariablesHandler().environment,
    version: getEnvironmentVariablesHandler().version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

  // Set Datadog user
  var token = authContext.getCachedToken('6e01c747-cb4c-4a1e-985c-708dc1a8943f');
  var profile = jwtDecode(token);
  datadogRum.setUser({
    email: profile.upn,
    name: profile.given_name,
  });

  return (
    <DarkModeProvider>
      <Layout>
        <TppSwitcher />
      </Layout>
    </DarkModeProvider>
  );
};

export default APP_CA;
