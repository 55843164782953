import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const NTAN = ({ flyToWorkTicketInfo, setFlyToWorkTicketInfo, isOSFieldDisabledNoTravelNeeded }) => {
  const [isChecked, setIsChecked] = useState(false);
  const localStorageKey = `noFlightNeeded_ls_${flyToWorkTicketInfo.id}`;

  const handleNoFlightNeededChange = (e) => {
    const newValue = e.target.checked;
    setFlyToWorkTicketInfo((prevState) => ({ ...prevState, noFlightNeeded: newValue }));
    sessionStorage.setItem(localStorageKey, JSON.stringify(newValue));
    setIsChecked(newValue);

    // Set a timeout to clear the value after 1 minute (60000 milliseconds)
    // setTimeout(() => {
    //     setFlyToWorkTicketInfo((prevState) => ({ ...prevState, noFlightNeeded: false }));
    //     localStorage.removeItem(localStorageKey);
    //     setIsChecked(false);
    // }, 60000);
  };

  useEffect(() => {
    const savedNoFlightNeeded = sessionStorage.getItem(localStorageKey);
    if (savedNoFlightNeeded !== null) {
      const parsedValue = JSON.parse(savedNoFlightNeeded);
      setFlyToWorkTicketInfo((prevState) => ({
        ...prevState,
        noFlightNeeded: parsedValue,
      }));
      setIsChecked(parsedValue);
    } else {
      const initialChecked =
        typeof flyToWorkTicketInfo.noFlightNeeded === 'boolean' ? flyToWorkTicketInfo.noFlightNeeded : false;
      setIsChecked(initialChecked);
    }
  }, [setFlyToWorkTicketInfo, flyToWorkTicketInfo.noFlightNeeded, localStorageKey]);

  return (
    <Form.Check
      inline
      label="No travel arrangements needed"
      name="no_flight_needed_checkbox"
      type="checkbox"
      id="noFlightneeded_checkbox"
      value="noFlightNeeded"
      checked={isChecked}
      onChange={handleNoFlightNeededChange}
      disabled={isOSFieldDisabledNoTravelNeeded()}
    />
  );
};

export default React.memo(NTAN);
