import { DS_F2W_HR_Team } from 'Constants/F2wRolesConstants';
import { DS_F2W_Edit } from 'Constants/F2wRolesConstants';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';
import { GET_USER_SUCCESS } from '../../../Constants/UserConstants';

var defaultState = {
  given_name: '',
  family_name: '',
  upn: '',
  roles: [],
};

console.log('DS_F2W_HR_Team', DS_F2W_HR_Team);
console.log('DS_F2W_Edit', DS_F2W_Edit);
console.log('DS_F2W_Business_Travel_Team', DS_F2W_Business_Travel_Team);

export default function currentUserReducer(state = defaultState, action) {
  let isProd = window.location.hostname.includes('fly2work.tui-dx.com');
  if (!isProd) {
    switch (action.type) {
      case GET_USER_SUCCESS:
        return {
          ...action.data.user,
          roles: [DS_F2W_Edit, DS_F2W_Business_Travel_Team, DS_F2W_HR_Team],
        };
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case GET_USER_SUCCESS:
        return {
          ...action.data.user,
        };
      default:
        return state;
    }
  }
}
