import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Row, Button, Col } from 'react-bootstrap';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';

import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import RestClient from 'Services/RestClientService';
import FlyToWorkTicketInformationTab from 'Views/TicketProfile/Tabs/FlyToWorkTicketInformationTab';
import BusinessSupportTab from 'Views/TicketProfile/Tabs/BusinessSupportTab';
import BusinessTravelTeamTab from 'Views/TicketProfile/Tabs/BusinessTravelTeamTab';
import HistoryTab from 'Views/TicketProfile/Tabs/HistoryTab';
import AttachementsTab from 'Views/TicketProfile/Tabs/AttachementsTab';
import { handleDownloadPdf } from 'Handlers/GlobalFunctions';
import { DS_F2W_Business_Travel_Team, DS_F2W_Edit, DS_F2W_HR_Team } from 'Constants/F2wRolesConstants';

const TicketProfile = () => {
  const { status, ticketId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const [flyToWorkTicketInfo, setFlyToWorkTicketInfo] = useState({});
  const [refreshComponent, setRefreshComponent] = useState(0);

  const validFollowingStatus = [
    { value: 'pendingbtt', label: 'Pending Btt' },
    { value: 'pendingdes', label: 'Pending Des' },
    { value: 'confirmed', label: 'Confirmed' },
  ];

  const confirmedStatus = [
    { value: 'modified', label: 'modified' },
    { value: 'cancelled', label: 'cancelled' },
  ];

  const filteredStatus =
    flyToWorkTicketInfo?.status?.toLowerCase() === 'confirmed' && userRoles?.includes(DS_F2W_Business_Travel_Team)
      ? validFollowingStatus
      : validFollowingStatus.filter((status) => status.value !== flyToWorkTicketInfo?.status?.toLowerCase());

  useEffect(() => {
    if (!status || !ticketId) return;
    dispatch(setLoader(true));
    RestClient.Get(`flight-ticket/${status}/${ticketId}`)
      .then((ticketInfo) => {
        if (!ticketInfo || !ticketInfo.ok) return;
        document.title = `${ticketInfo?.data.firstName} ${ticketInfo?.data.lastName} - Ticket profile`;
        setFlyToWorkTicketInfo(ticketInfo.data);
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, [status, ticketId, refreshComponent]);

  const handleSubmitChange = () => {
    if (flyToWorkTicketInfo.status.toLowerCase() === 'new') {
      const error = validateNewStatusBeforeSubmit();
      if (error) {
        toastr.error('Error', error);
        return;
      }
    } else if (flyToWorkTicketInfo.followingStatus?.toLowerCase() === 'confirmed') {
      const error = validateConfirmedStatusBeforeSubmit();
      if (error) {
        toastr.error('Error', error);
        return;
      }
    }

    toastr.confirm('Are you sure you want to submit this ticket?', {
      onOk: () => {
        dispatch(setLoader(true));
        RestClient.Post(`flight-ticket/submit-status-from-to/${flyToWorkTicketInfo.id}`, {
          followingStatus: flyToWorkTicketInfo.followingStatus,
          confirmedStatus: flyToWorkTicketInfo.confirmedStatus,
          isNTANSelected: flyToWorkTicketInfo.noFlightNeeded,
        })
          .then((response) => {
            if (!response || !response?.ok) {
              toastr.error('Error', response?.message);
              return;
            }
            const origin = window.location.origin;
            const url = `${origin}/${response.data.status}/${response.data.id}`;
            window.location.href = url;
            setRefreshComponent(Math.random());
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      },
    });
  };

  const validateNewStatusBeforeSubmit = () => {
    let result = '';

    if (!flyToWorkTicketInfo.preferredFlightDate) {
      result = 'Preferred Flight Date in tab Business Support is required';
    }

    if (!flyToWorkTicketInfo.noFlightNeeded) {
      if (!flyToWorkTicketInfo.departureAirports) {
        result = 'Departure airports in tab Business Support is required';
      }

      if (!flyToWorkTicketInfo.arrivalAirports) {
        result = 'Arrival airports in tab Business Support is required';
      }
    }

    if (flyToWorkTicketInfo.isBookReturnFlight) {
      if (!flyToWorkTicketInfo.brfReturnFlightDate) {
        result = 'BRF Return Preferred Flight Date in tab Business Support is required';
      }

      if (!flyToWorkTicketInfo.brfDepartureAirport) {
        result = 'BRF Return Departure airport in tab Business Support is required';
      }

      if (!flyToWorkTicketInfo.brfArrivalAirport) {
        result = 'BRF Arrival airport in tab Business Support is required';
      }
    }

    if (flyToWorkTicketInfo.isRailAndFly) {
      if (!flyToWorkTicketInfo.homeTrainStationName) {
        result = 'Train station name in tab Business Support is required';
      }
    }

    return result;
  };

  const validateConfirmedStatusBeforeSubmit = () => {
    let result = '';

    if (!flyToWorkTicketInfo.noFlightNeeded) {
      if (!flyToWorkTicketInfo.flights || !flyToWorkTicketInfo.flights.some((flight) => flight.flightNumber)) {
        result = 'Flight in tab Business Tavel Team is required';
      }
    }

    return result;
  };

  const handleRemoveFlightTicket = () => {
    toastr.confirm('Are you sure you want to remove this ticket?', {
      onOk: () => {
        dispatch(setLoader(true));
        RestClient.Post(`flight-ticket/delete/${flyToWorkTicketInfo.id}`)
          .then((response) => {
            if (!response || !response?.ok) {
              toastr.error('Error', response?.message);
              return;
            }
            const origin = window.location.origin;
            const url = `${origin}/all`;
            window.location.href = url;
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      },
    });
  };

  const isChangeStatusDropDownHidden = () => {
    let result = true;

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed')
    ) {
      result = false;
    }

    if (
      userRoles?.includes(DS_F2W_Business_Travel_Team) &&
      (flyToWorkTicketInfo.status?.toLowerCase() === 'confirmed' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() === 'pendingbtt')
    ) {
      result = false;
    }

    return result;
  };

  const isBussinesTravelTeamTabHidden = () => {
    let result = true;

    if (userRoles?.includes(DS_F2W_Business_Travel_Team)) {
      result = false;
    }

    if (
      (userRoles?.includes(DS_F2W_Edit) || userRoles?.includes(DS_F2W_HR_Team)) &&
      flyToWorkTicketInfo.status?.toLowerCase() == 'confirmed'
    ) {
      result = false;
    }

    return result;
  };

  const isSubmitHidden = () => {
    let result = true;

    if (userRoles?.includes(DS_F2W_HR_Team) && flyToWorkTicketInfo.status?.toLowerCase() == 'pendinghr') {
      result = false;
    }

    if (
      userRoles?.includes(DS_F2W_Edit) &&
      (flyToWorkTicketInfo.status?.toLowerCase() == 'new' ||
        flyToWorkTicketInfo.status?.toLowerCase() == 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() == 'confirmed')
    ) {
      result = false;
    }

    if (
      userRoles?.includes(DS_F2W_Business_Travel_Team) &&
      (flyToWorkTicketInfo.status?.toLowerCase() == 'pendingdes' ||
        flyToWorkTicketInfo.status?.toLowerCase() == 'pendingbtt' ||
        flyToWorkTicketInfo.status?.toLowerCase() == 'confirmed')
    ) {
      result = false;
    }

    return result;
  };

  const checkFollowingStatusValues = () => {
    if (flyToWorkTicketInfo.noFlightNeeded && flyToWorkTicketInfo.status?.toLowerCase() === 'pendingdes') {
      return filteredStatus.filter((status) => status.value !== 'pendingbtt');
    }
    return filteredStatus;
  };

  return (
    <React.Fragment>
      <Tabs
        defaultActiveKey={(window.location.hash ? window.location.hash.split('#')[1] : undefined) || 'businessSupport'}
        className="staff-profile-tabs"
      >
        <Tab eventKey="businessSupport" title="Operation Support">
          <BusinessSupportTab
            flyToWorkTicketInfo={flyToWorkTicketInfo}
            setRefreshComponent={setRefreshComponent}
            refreshComponent={refreshComponent}
            setFlyToWorkTicketInfo={setFlyToWorkTicketInfo}
          />
        </Tab>
        <Tab
          eventKey="businesstravelTeam"
          title="Business Travel Team"
          tabClassName={isBussinesTravelTeamTabHidden() ? 'd-none' : ''}
        >
          <BusinessTravelTeamTab flyToWorkTicketInfo={flyToWorkTicketInfo} setRefreshComponent={setRefreshComponent} />
        </Tab>
        <Tab eventKey="attachements" title="Ticket attachements">
          <AttachementsTab flyToWorkTicketInfo={flyToWorkTicketInfo} setRefreshComponent={setRefreshComponent} />
        </Tab>
        <Tab
          eventKey="history"
          title="Ticket history"
          tabClassName={userRoles?.includes(DS_F2W_Business_Travel_Team) ? '' : 'd-none'}
        >
          <HistoryTab flyToWorkTicketInfo={flyToWorkTicketInfo} refreshComponent={refreshComponent} />
        </Tab>
        <Tab
          eventKey="flyToWorkTicketInfo"
          title="Ticket Info"
          tabClassName={userRoles?.includes(DS_F2W_Business_Travel_Team) ? '' : 'd-none'}
        >
          <FlyToWorkTicketInformationTab flyToWorkTicketInfo={flyToWorkTicketInfo} />
        </Tab>
      </Tabs>
      <hr></hr>
      <Row style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1% 1% 0% 0%' }} lg={6}>
        <div hidden={isChangeStatusDropDownHidden()}>
          <Col>
            <Select
              options={checkFollowingStatusValues(filteredStatus)}
              isClearable
              menuPlacement="top"
              onChange={(e) => {
                if (!e) {
                  setFlyToWorkTicketInfo({
                    ...flyToWorkTicketInfo,
                    followingStatus: '',
                    confirmedStatus: '',
                  });
                } else {
                  setFlyToWorkTicketInfo({
                    ...flyToWorkTicketInfo,
                    followingStatus: e.value,
                  });
                }
              }}
              value={
                filteredStatus.find((status) => status.value === flyToWorkTicketInfo?.followingStatus?.toLowerCase()) ||
                null
              }
            />
          </Col>
        </div>
        <div
          hidden={
            !flyToWorkTicketInfo?.followingStatus || flyToWorkTicketInfo?.followingStatus?.toLowerCase() !== 'confirmed'
          }
        >
          <Col>
            <Select
              options={confirmedStatus}
              isClearable
              menuPlacement="top"
              onChange={(e) => {
                if (!e) {
                  setFlyToWorkTicketInfo({ ...flyToWorkTicketInfo, confirmedStatus: '' });
                } else {
                  setFlyToWorkTicketInfo({ ...flyToWorkTicketInfo, confirmedStatus: e.value });
                }
              }}
            />
          </Col>
        </div>
      </Row>
      <Row style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1%' }}>
        <Button variant="info" onClick={() => handleSubmitChange()} size="sm" hidden={isSubmitHidden()}>
          Submit
        </Button>
        <Button
          variant="primary"
          style={{ marginRight: '10px' }}
          onClick={() =>
            handleDownloadPdf(
              flyToWorkTicketInfo.id,
              flyToWorkTicketInfo.firstName + ' ' + flyToWorkTicketInfo.lastName + '.pdf'
            )
          }
          size="sm"
          hidden={!userRoles?.includes(DS_F2W_Business_Travel_Team)}
        >
          Pdf
        </Button>
        <Button
          variant="danger"
          style={{ marginRight: '10px' }}
          onClick={() => handleRemoveFlightTicket(flyToWorkTicketInfo.id)}
          size="sm"
          hidden={!userRoles?.includes(DS_F2W_Business_Travel_Team)}
        >
          Delete
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default TicketProfile;
