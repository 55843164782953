import { toastr } from 'react-redux-toastr';

import RestClient from 'Services/RestClientService';

export function handleDownloadPdf(flightTicketId, fileName) {
  RestClient.Download(`pdf/${flightTicketId}`, {}, fileName)
    .then((response) => {
      // console.log(response);
      if (response) {
        toastr.success('Success', 'PDF downloaded successfully.');
      } else {
        toastr.error('Error', 'Can´t download the PDF file. Please try again later.');
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function setGreenLight(flightTicketId, gridRef, createServerSideDatasource) {
  RestClient.Post(`flight-ticket/set-greenLight/${flightTicketId}`)
    .then((response) => {
      // console.log(response);
      if (response) {
        toastr.success('Success', 'Changed to greenlighted.');
        gridRef.current.api.setServerSideDatasource(createServerSideDatasource());
      } else {
        toastr.error('Error', 'Can´t change status.');
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function handleDeleteFlightTicket(flightTicketId, gridRef, createServerSideDatasource) {
  RestClient.Post(`flight-ticket/delete/${flightTicketId}`)
    .then((response) => {
      // console.log(response);
      if (response) {
        toastr.success('Success', 'Removed !');
        gridRef.current.api.setServerSideDatasource(createServerSideDatasource());
      } else {
        toastr.error('Error', response.message);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
