import React, { useEffect } from 'react';

import StatusStaffTabsRow from 'Components/StatusStaffTabsRow';
import BusinessSupportCard from 'Views/TicketProfile/Components/BusinessSupportCard';
import CommentsCard from 'Views/TicketProfile/Components/CommentsCard';

const BusinessSupportTab = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent, refreshComponent, setFlyToWorkTicketInfo } = props;

  useEffect(() => {
    console.log('refreshComponent', refreshComponent);
  }, [refreshComponent]);

  return (
    <React.Fragment>
      <StatusStaffTabsRow flyToWorkTicketInfo={flyToWorkTicketInfo} />
      <hr style={{ marginTop: 0 }} />
      <BusinessSupportCard
        flyToWorkTicketInfo={flyToWorkTicketInfo}
        setRefreshComponent={setRefreshComponent}
        setFlyToWorkTicketInfo={setFlyToWorkTicketInfo}
      />
      <CommentsCard
        flyToWorkTicketInfo={flyToWorkTicketInfo}
        setRefreshComponent={setRefreshComponent}
        refreshComponent={refreshComponent}
      />
    </React.Fragment>
  );
};

export default BusinessSupportTab;
