import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { authContext } from 'Configuration/AdalConfiguration';

const Avatar = () => {
  const [showInfo, setShowInfo] = useState(false);
  const handleAvatarClick = () => {
    setShowInfo(!showInfo);
  };

  const currentUser = useSelector((state) => state.currentUser);

  const formatRoles = (roles) => {
    if (roles.length === 0) {
      return '';
    }
    let result = roles.filter((value) => value.includes('DS_F2W'));
    return result;
  };

  return (
    <div className="navbar-nav ml-auto position-relative">
      <div style={{ margin: '4% 4% 0 0' }}>{currentUser.upn}</div>
      <Button variant="link" className="p-0" onClick={handleAvatarClick}>
        <div
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#007bff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1rem',
          }}
        >
          {currentUser.given_name.charAt(0) + currentUser.family_name.charAt(0)}
        </div>
      </Button>
      {showInfo && (
        <div className="user-info">
          <Row>
            <Col xs={12}>{currentUser.family_name + ', ' + currentUser.given_name}</Col>
            <Col xs={12}>{currentUser.upn}</Col>
            <Col xs={12}>{formatRoles(currentUser.roles)}</Col>
            <Col xs={12} className="text-right" style={{ marginTop: '5%' }}>
              <Button
                size="sm"
                onClick={() => {
                  // console.log('logout');
                  authContext.logOut();
                }}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Avatar;
