import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { DATE_FORMAT_ISO, DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
// import file
import airports from 'Constants/airports.json';

const NewFlightTicketModal = (props) => {
  const {
    newManualFlightTicket,
    setNewManualFlightTicket,
    newManualFlightTicketErrors,
    setNewManualFlightTicketErrors,
  } = props;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create custom object to send
      setNewManualFlightTicket({
        ...newManualFlightTicket,
        selectedFile: file,
      });
    }
  };

  const parseBooleanOrNull = (value) => {
    if (value === 'true' || value === true) return true;
    if (value === 'false' || value === false) return false;
    return null;
  };

  const seasonsOptionList = useSelector((state) => state.masterData.seasons);
  const seasonsOptionListFilter = seasonsOptionList
    .filter((season) => season.value.toLowerCase().indexOf('tbc') === -1 && season.value)
    .sort((a, b) => a.value.localeCompare(b.value));

  const countriesOptionList = useSelector((state) => state.masterData.countries);

  const destinationsOptionList = useSelector((state) => state.masterData.destinations);
  const destinationsOptionListFilter = destinationsOptionList
    .filter(
      (destination) =>
        destination.value.toLowerCase().indexOf('late starters') === -1 &&
        destination.value.toLowerCase().indexOf('standby') === -1 &&
        destination.value
    )
    .sort((a, b) => a.value.localeCompare(b.value));

  const jobTitlesOptionList = useSelector((state) => state.masterData.jobTitles);

  const mappedAirports = airports.map((airport) => {
    return {
      value: airport.iata_code,
      label: `${airport.iata_code} - ${airport.name}`,
    };
  });

  const sourceMarketList = [
    'TUI GAS',
    'TUI Poland',
    'TUI UK&I',
    'TUI Denmark',
    'TUI Finland',
    'TUI Norway',
    'TUI Sweden',
    'TUI Netherlands',
    'TUI Belgium',
    'TUI UK&I - Other',
    'TUI Czech Republic',
    'TUI France',
    'TUI Italy',
    'TUI Spain',
    'TUI Hungary',
  ];
  const sourceMarketsOptionsList = sourceMarketList.map((item) => ({ label: item, value: item }));

  const typeOfFlightList = ['Holiday', 'Visa', 'Training', 'Sick-leave / absence', 'Welfare'];
  const typeOfFlightOptionsList = typeOfFlightList.map((item) => ({ label: item, value: item }));
  const flightDirections = ['Arriving', 'Departure'];
  const flightDirectionsOptionsList = flightDirections.map((item) => ({ label: item, value: item }));

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-check mr-2"></i> Business support information
      </Card.Header>
      <Card.Body>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={newManualFlightTicket.firstName}
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, firstName: e.target.value });
                setNewManualFlightTicketErrors({});
              }}
            />
            {newManualFlightTicketErrors.firstName && (
              <small className="form-text-red">{newManualFlightTicketErrors.firstName}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={newManualFlightTicket.lastName}
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, lastName: e.target.value });
                setNewManualFlightTicketErrors({});
              }}
            />
            {newManualFlightTicketErrors.lastName && (
              <small className="form-text-red">{newManualFlightTicketErrors.lastName}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Second Last Name</Form.Label>
            <Form.Control
              type="text"
              defaultValue={newManualFlightTicket.lastName2}
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, lastName2: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Date of birth</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{
                placeholder: DATE_FORMAT_WEB,
              }}
              value={DateTimeFormater(newManualFlightTicket.dateOfBirth, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                if (moment.isMoment(e)) {
                  setNewManualFlightTicket({
                    ...newManualFlightTicket,
                    dateOfBirth: e.format(DATE_FORMAT_ISO),
                  });
                  setNewManualFlightTicketErrors({});
                } else {
                  console.error(
                    'e is not a Moment object, you are not able to type manually in the date field. values typed : ' + e
                  );
                  return null;
                }
              }}
            />
            {newManualFlightTicketErrors.preferredFlightDate && (
              <small className="form-text-red">{newManualFlightTicketErrors.preferredFlightDate}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="text"
              defaultValue={newManualFlightTicket.phone}
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, phone: e.target.value });
                setNewManualFlightTicketErrors({});
              }}
            />
            {newManualFlightTicketErrors.phone && (
              <small className="form-text-red">{newManualFlightTicketErrors.phone}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Gender</Form.Label>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Male"
                  name="gender_radio_button"
                  type="radio"
                  id="male_radio_button"
                  value="Male"
                  checked={newManualFlightTicket.gender === 'Male'}
                  onChange={(e) => {
                    setNewManualFlightTicket({ ...newManualFlightTicket, gender: e.target.value });
                    setNewManualFlightTicketErrors({});
                  }}
                />
                <Form.Check
                  inline
                  label="Female"
                  name="gender_radio_button"
                  type="radio"
                  id="female_radio_button"
                  value="Female"
                  checked={newManualFlightTicket.gender === 'Female'}
                  onChange={(e) => {
                    setNewManualFlightTicket({ ...newManualFlightTicket, gender: e.target.value });
                    setNewManualFlightTicketErrors({});
                  }}
                />
                <Form.Check
                  inline
                  label="Unknow"
                  name="gender_radio_button"
                  type="radio"
                  id="unknow_radio_button"
                  value="Unknow"
                  checked={newManualFlightTicket.gender === 'Unknow'}
                  onChange={(e) => {
                    setNewManualFlightTicket({ ...newManualFlightTicket, gender: e.target.value });
                    setNewManualFlightTicketErrors({});
                  }}
                />
              </Col>
            </Row>
            {newManualFlightTicketErrors.gender && (
              <small className="form-text-red">{newManualFlightTicketErrors.gender}</small>
            )}
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Staff Source Market</Form.Label>
            <Select
              options={sourceMarketsOptionsList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, staffSourceMarket: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={sourceMarketsOptionsList.find((x) => x.value === newManualFlightTicket.staffSourceMarket) || null}
            />
            {newManualFlightTicketErrors.staffSourceMarket && (
              <small className="form-text-red">{newManualFlightTicketErrors.staffSourceMarket}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Source Market Budget</Form.Label>
            <Select
              options={sourceMarketsOptionsList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, staffSourceMarketBudget: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={
                sourceMarketsOptionsList.find((x) => x.value === newManualFlightTicket.staffSourceMarketBudget) || null
              }
            />
            {newManualFlightTicketErrors.staffSourceMarketBudget && (
              <small className="form-text-red">{newManualFlightTicketErrors.staffSourceMarketBudget}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Season</Form.Label>
            <Select
              options={seasonsOptionListFilter}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, season: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={seasonsOptionListFilter.find((x) => x.value === newManualFlightTicket.season) || null}
            />
            {newManualFlightTicketErrors.season && (
              <small className="form-text-red">{newManualFlightTicketErrors.season}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Country</Form.Label>
            <Select
              options={countriesOptionList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, country: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={countriesOptionList.find((x) => x.value === newManualFlightTicket.country) || null}
            />
            {newManualFlightTicketErrors.country && (
              <small className="form-text-red">{newManualFlightTicketErrors.country}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Destination</Form.Label>
            <Select
              options={destinationsOptionListFilter}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, destination: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={destinationsOptionListFilter.find((x) => x.value === newManualFlightTicket.destination) || null}
            />
            {newManualFlightTicketErrors.destination && (
              <small className="form-text-red">{newManualFlightTicketErrors.destination}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Position Job title</Form.Label>
            <Select
              options={jobTitlesOptionList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, jobTitle: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={jobTitlesOptionList.find((x) => x.value === newManualFlightTicket.jobTitle) || null}
            />
            {newManualFlightTicketErrors.jobTitle && (
              <small className="form-text-red">{newManualFlightTicketErrors.jobTitle}</small>
            )}
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              Planned Assignment{' '}
              {newManualFlightTicket?.direction?.toLowerCase() === 'arriving'
                ? 'Start'
                : !newManualFlightTicket?.direction
                  ? ''
                  : 'End'}{' '}
              Date
            </Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{
                placeholder: DATE_FORMAT_WEB,
                disabled: true,
              }}
              value={DateTimeFormater(newManualFlightTicket.plannedAssignmentStartDate, null, DATE_FORMAT_WEB)}
              // isValidDate={(current) => {
              //   return current.isAfter(moment());
              // }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Preferred Flight Date</Form.Label>
            <Datetime
              timeFormat={false}
              dateFormat={DATE_FORMAT_WEB}
              closeOnSelect
              utc={true}
              inputProps={{
                placeholder: DATE_FORMAT_WEB,
              }}
              value={DateTimeFormater(newManualFlightTicket.preferredFlightDate, null, DATE_FORMAT_WEB)}
              onChange={(e) => {
                if (moment.isMoment(e)) {
                  setNewManualFlightTicket({
                    ...newManualFlightTicket,
                    preferredFlightDate: e.format(DATE_FORMAT_ISO),
                  });
                  setNewManualFlightTicketErrors({});
                } else {
                  console.error(
                    'e is not a Moment object, you are not able to type manually in the date field. values typed : ' + e
                  );
                  return null;
                }
              }}
            />
            {newManualFlightTicketErrors.preferredFlightDate && (
              <small className="form-text-red">{newManualFlightTicketErrors.preferredFlightDate}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Type of flight</Form.Label>
            <Select
              options={typeOfFlightOptionsList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, typeOfFlight: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={typeOfFlightOptionsList.find((x) => x.value === newManualFlightTicket.typeOfFlight) || null}
            />
            {newManualFlightTicketErrors.typeOfFlight && (
              <small className="form-text-red">{newManualFlightTicketErrors.typeOfFlight}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Direction</Form.Label>
            <Select
              options={flightDirectionsOptionsList}
              isSearchable
              isClearable
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, direction: e?.value });
                setNewManualFlightTicketErrors({});
              }}
              value={flightDirectionsOptionsList.find((x) => x.value === newManualFlightTicket?.direction) || null}
            />
            {newManualFlightTicketErrors.direction && (
              <small className="form-text-red">{newManualFlightTicketErrors.direction}</small>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Additional email to notification </Form.Label>
            <CreatableSelect
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                if (!e) return null;
                const emailRegex = /^[^\s@]+@(?:tui\.com|tui\.de)$/i;
                if (!e.every((email) => emailRegex.test(email.value))) {
                  var popEmail = e.pop();
                  toastr.warning('Error', `${popEmail.value} is invalid email, please review.`);
                  setNewManualFlightTicket({ ...newManualFlightTicket, additionNotificationEmails: null });
                  return null;
                }
                setNewManualFlightTicket({ ...newManualFlightTicket, additionNotificationEmails: e });
              }}
              value={newManualFlightTicket.additionNotificationEmails}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Departure Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, departureAirports: e?.map((x) => x.value) });
                setNewManualFlightTicketErrors({});
              }}
              value={mappedAirports
                .filter((x) => newManualFlightTicket.departureAirports?.includes(x.value) || null)
                .sort(
                  (a, b) =>
                    newManualFlightTicket.departureAirports.indexOf(a.value) -
                    newManualFlightTicket.departureAirports.indexOf(b.value)
                )}
              isDisabled={newManualFlightTicket.noFlightNeeded}
            />
            {newManualFlightTicketErrors.departureAirports && (
              <small className="form-text-red">{newManualFlightTicketErrors.departureAirports}</small>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Arrival Airports</Form.Label>
            <Select
              options={mappedAirports}
              isSearchable
              isClearable
              isMulti
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, arrivalAirports: e?.map((x) => x.value) });
                setNewManualFlightTicketErrors({});
              }}
              value={mappedAirports
                .filter((x) => newManualFlightTicket.arrivalAirports?.includes(x.value) || null)
                .sort(
                  (a, b) =>
                    newManualFlightTicket.arrivalAirports.indexOf(a.value) -
                    newManualFlightTicket.arrivalAirports.indexOf(b.value)
                )}
              isDisabled={newManualFlightTicket.noFlightNeeded}
            />
            {newManualFlightTicketErrors.arrivalAirports && (
              <small className="form-text-red">{newManualFlightTicketErrors.arrivalAirports}</small>
            )}
          </Form.Group>
        </Form.Row>
        <hr></hr>
        <Form.Row>
          <Form.Group as={Col}>
            <Row>
              <Col>
                <Form.Check
                  inline
                  label="Hotel Needed"
                  name="is_hotel_needed_checkbox"
                  type="checkbox"
                  id="hotelNeeded_checkbox"
                  value="isHotelNeeded"
                  checked={newManualFlightTicket.isHotelNeeded}
                  onChange={(e) => {
                    setNewManualFlightTicket({ ...newManualFlightTicket, isHotelNeeded: e.target.checked });
                  }}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Book Return Flight"
                  name="is_book_return_flight_checkbox"
                  type="checkbox"
                  id="isBookReturnFlight_checkbox"
                  value="isBookReturnFlight"
                  checked={newManualFlightTicket.isBookReturnFlight}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      // reset values for BRF fields
                      setNewManualFlightTicket({
                        ...newManualFlightTicket,
                        brfReturnFlightDate: null,
                        brfDepartureAirport: null,
                        brfArrivalAirport: null,
                        isBookReturnFlight: false,
                      });
                    } else {
                      setNewManualFlightTicket({ ...newManualFlightTicket, isBookReturnFlight: e.target.checked });
                    }
                  }}
                />
              </Col>
              <Col>
                <Form.Check
                  inline
                  label="Train ticket"
                  name="is_rail_and_fly_checkbox"
                  type="checkbox"
                  id="isRailAndFly_checkbox"
                  value="isRailAndFly"
                  checked={newManualFlightTicket.isRailAndFly}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      // reset home train station name
                      setNewManualFlightTicket({
                        ...newManualFlightTicket,
                        homeTrainStationName: '',
                        isRailAndFly: false,
                      });
                    } else {
                      setNewManualFlightTicket({ ...newManualFlightTicket, isRailAndFly: e.target.checked });
                    }
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form.Row>
        <div hidden={!parseBooleanOrNull(newManualFlightTicket.isBookReturnFlight)}>
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Return Flight Date</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB }}
                value={DateTimeFormater(newManualFlightTicket.brfReturnFlightDate, null, DATE_FORMAT_WEB)}
                onChange={(e) => {
                  if (moment.isMoment(e)) {
                    setNewManualFlightTicket({
                      ...newManualFlightTicket,
                      brfReturnFlightDate: e.format(DATE_FORMAT_ISO),
                    });
                    setNewManualFlightTicketErrors({});
                  } else {
                    console.error(
                      'e is not a Moment object, you are not able to type manually in the date field. values typed : ' +
                        e
                    );
                    return null;
                  }
                }}
                // isValidDate={(current) => {
                //   return current.isAfter(newManualFlightTicket.ticketStartDate);
                // }}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Departure Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  setNewManualFlightTicket({ ...newManualFlightTicket, brfDepartureAirport: e?.map((x) => x.value) });
                  setNewManualFlightTicketErrors({});
                }}
                value={mappedAirports
                  .filter((x) => newManualFlightTicket.brfDepartureAirport?.includes(x.value) || null)
                  .sort(
                    (a, b) =>
                      newManualFlightTicket.brfDepartureAirport.indexOf(a.value) -
                      newManualFlightTicket.brfDepartureAirport.indexOf(b.value)
                  )}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>(BRF) Arrival Airport</Form.Label>
              <Select
                options={mappedAirports}
                isSearchable
                isClearable
                isMulti
                onChange={(e) => {
                  setNewManualFlightTicket({ ...newManualFlightTicket, brfArrivalAirport: e?.map((x) => x.value) });
                  setNewManualFlightTicketErrors({});
                }}
                value={mappedAirports
                  .filter((x) => newManualFlightTicket.brfArrivalAirport?.includes(x.value) || null)
                  .sort(
                    (a, b) =>
                      newManualFlightTicket.brfArrivalAirport.indexOf(a.value) -
                      newManualFlightTicket.brfArrivalAirport.indexOf(b.value)
                  )}
              />
            </Form.Group>
          </Form.Row>
        </div>
        <div hidden={!parseBooleanOrNull(newManualFlightTicket.isRailAndFly)}>
          <hr></hr>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Train station name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={newManualFlightTicket.homeTrainStationName}
                onBlur={(e) => {
                  setNewManualFlightTicket({ ...newManualFlightTicket, homeTrainStationName: e.target.value });
                }}
              />
            </Form.Group>
          </Form.Row>
        </div>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={newManualFlightTicket.comment}
              onChange={(e) => {
                setNewManualFlightTicket({ ...newManualFlightTicket, comment: e.target.value });
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Upload Files</Form.Label>
            <Form.Control type="file" multiple onChange={handleFileChange} />
          </Form.Group>
        </Form.Row>
      </Card.Body>
    </Card>
  );
};

export default NewFlightTicketModal;
